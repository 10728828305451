// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1920px) {
  .adjust-padding {
    padding-left: 251.5px;
    padding-right: 251.5px;
  }
}

@media screen and (max-width: 1680px) {
  .adjust-padding {
    padding-left: 132px;
    padding-right: 132px;
  }
}

@media screen and (max-width: 1600px) {
  .adjust-padding {
    padding-left: 91.2px;
    padding-right: 91.2px;
  }
}

@media screen and (max-width: 1440px) {
  .adjust-padding {
    padding-left: 11px;
    padding-right: 11px;
  }
}

/* @media screen and (max-width: 1366px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 1280px) {
  .adjust-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/pages/merchant/home/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;IACrB,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;;;;;;;;;;;;;;;;;;;GAmBG","sourcesContent":["@media screen and (max-width: 1920px) {\n  .adjust-padding {\n    padding-left: 251.5px;\n    padding-right: 251.5px;\n  }\n}\n\n@media screen and (max-width: 1680px) {\n  .adjust-padding {\n    padding-left: 132px;\n    padding-right: 132px;\n  }\n}\n\n@media screen and (max-width: 1600px) {\n  .adjust-padding {\n    padding-left: 91.2px;\n    padding-right: 91.2px;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .adjust-padding {\n    padding-left: 11px;\n    padding-right: 11px;\n  }\n}\n\n/* @media screen and (max-width: 1366px) {\n  .adjust-padding {\n    padding-left: 35px;\n    padding-right: 35px;\n  }\n}\n\n@media screen and (max-width: 1280px) {\n  .adjust-padding {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .adjust-padding {\n    padding-left: 35px;\n    padding-right: 35px;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
