// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width: 2500px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 31px;
  }
}

@media screen and (max-width: 2300px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 29px;
  }
}

@media screen and (max-width: 2200px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 27px;
  }
}

@media screen and (max-width: 2000px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 25px;
  }
}

@media screen and (max-width: 1920px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 24px;
  }
}

@media screen and (max-width: 1640px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 23px;
  }
}

@media screen and (max-width: 1540px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1240px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 17px;
  }
}


/* @media screen and (max-width: 1366px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 1280px) {
  .adjust-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/pages/tv/styles.css"],"names":[],"mappings":";AACA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;;AAGA;;;;;;;;;;;;;;;;;;;GAmBG","sourcesContent":["\n@media screen and (max-width: 2500px) {\n  .adjust-text {\n    padding-top: 15px;\n    font-size: 31px;\n  }\n}\n\n@media screen and (max-width: 2300px) {\n  .adjust-text {\n    padding-top: 15px;\n    font-size: 29px;\n  }\n}\n\n@media screen and (max-width: 2200px) {\n  .adjust-text {\n    padding-top: 15px;\n    font-size: 27px;\n  }\n}\n\n@media screen and (max-width: 2000px) {\n  .adjust-text {\n    padding-top: 15px;\n    font-size: 25px;\n  }\n}\n\n@media screen and (max-width: 1920px) {\n  .adjust-text {\n    padding-top: 15px;\n    font-size: 24px;\n  }\n}\n\n@media screen and (max-width: 1640px) {\n  .adjust-text {\n    padding-top: 14px;\n    font-size: 23px;\n  }\n}\n\n@media screen and (max-width: 1540px) {\n  .adjust-text {\n    padding-top: 14px;\n    font-size: 20px;\n  }\n}\n\n@media screen and (max-width: 1240px) {\n  .adjust-text {\n    padding-top: 14px;\n    font-size: 17px;\n  }\n}\n\n\n/* @media screen and (max-width: 1366px) {\n  .adjust-padding {\n    padding-left: 35px;\n    padding-right: 35px;\n  }\n}\n\n@media screen and (max-width: 1280px) {\n  .adjust-padding {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .adjust-padding {\n    padding-left: 35px;\n    padding-right: 35px;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
