// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loader-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader {
    border-right-color: transparent;
    animation: loader-rotate 1s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/styles.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,yBAAyB;IAC7B;AACJ;AACA;IACI,+BAA+B;IAC/B,2CAA2C;AAC/C","sourcesContent":["@keyframes loader-rotate {\n    0% {\n        transform: rotate(0);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n.loader {\n    border-right-color: transparent;\n    animation: loader-rotate 1s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
